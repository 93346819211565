import React, { useEffect, useState } from "react";
import SEO from "../../components/seo";
import Layout from '../../components/winter-2022/Layout';
import Pdp from '../../components/winter-2022/Pdp';
import "../../styles/Global.css"
import "../../styles/layout.module.css"

const PdpPage = () => {

  return (
    <Layout type='secundary'>
      <SEO title="ISLA CORONA 2022" />
      <Pdp />
    </Layout>
  );
}

export default PdpPage;
